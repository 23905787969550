var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('button', {
    staticClass: "DAV-titleBar__toggle",
    class: {
      'DAV-titleBar__toggle--active': _vm.value
    },
    on: {
      "click": function click($event) {
        return _vm.updateToggle(_vm.value);
      }
    }
  }, [_c('span', [_vm._v("Toggle")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }