<template>
  <div>
    <button
      class="DAV-titleBar__toggle"
      :class="{
        'DAV-titleBar__toggle--active': value
      }"
      @click="updateToggle(value)"
    >
      <span>Toggle</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? Number(this.option.componentOption.properties[0].initialValue)
        : this.option.value.value;
    }
  },
  mounted() {
    this.value = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.initialValue);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateToggle(value) {
      value = !value;
      this.value = value;
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        value
      );
      if (this.$route.path === "/option") {
        this.updateProperty(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.DAV-titleBar__toggle {
  margin-left: 0;
}
</style>
